<template >


<div class="flexcenter ">
 <div class="container"  style="height: 80vh">
  <div class="row ">

    <div class="col-sm-12 col-lg-10 p-2 nowrap">
      <input type="text" v-model="qwe" @keyup.enter="getData()" class="pe-0 p-2 br375 nowrap" style="max-width: 100%; width: 100%" placeholder="Вопрос или описание...">
      <div @click="clear" style="margin-left: -25px; cursor: pointer; display: inline-block" >
        <i class='fa fa-close' ></i>
      </div>
    </div>

    <div class="col-sm-12 col-lg-2 p-2 text-start">
      <button @click="getData()" class="btn btn-primary p-2" v-html="btnname" style="min-width: 200px; min-height: 44px"></button>
    </div>

    <div class="col-lg-12 p-2">
      <textarea class="col-lg-12 p-2" v-model="answer" v-if="!inprogress && answer" style="height: 80vh; max-width: 100%;  width: 100%; border: 0px!important; outline: none !important;" placeholder="Здесь будет ответ..."></textarea>
    </div>

  </div>
 </div>
</div>


</template>

<script>
export default {
  name: 'MyForm',
  props: {
    msg: String
  },
  data() {
    return {
      prompt: '',
      qwe: '',
      answer: '',
      inprogress: 0,
      btnname: 'Запустить'
      }
  },
  methods: {
    clear() {

      this.qwe = "";
    },
    getData() {
      let self = this;
      localStorage.setItem('qwe', this.qwe)
      self.inprogress = 1;
      self.btnname = "<i class='fa fa-spinner fa-spin'></i>";

      this.axios({
        method: "post",
        url: "https://smartcub.ru/cht/_get/ai.php",
        data: { prompt: this.qwe }
      }).then(response => {
        self.answer = response.data;
        self.inprogress = 0;
        self.btnname = "Запустить";
      })
    },
  },
}
</script>


<style scoped>

.nowrap {
  white-space: nowrap;
}

.br375 {
  border-radius: 0.375rem;
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
